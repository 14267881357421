















import Component from 'vue-class-component';
import Vue from 'vue';

@Component({
  name: 'HelloWorld',
})
export default class HelloWorld extends Vue {}
